import React from "react";

export default function Notfound (props) {
    return (
        <div className="flex mt-10 w-full justify-center items-center home">
        <div className="block text-center">
            <img alt='' src = '/assets/img/astronauts.png'></img>
            <p className="font-bold text-[72px] text-slate-600 drop-shadow-2xl">{props.mstatus.code}</p>
            
        </div>
        </div>
    );

}