import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import "./customNavlink.css";

export const CustomNavLink = ({
  to,
  icon,
  title,
  styles,
  onClick = () => {},
}) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  let active = styles + " active";
  let noActive = styles;
  return (
    <Link onClick={onClick} to={to} className={match ? active : noActive}>
      {match ? (
        <>
          <img src={icon} alt={"icon"} width={30} />
          <p className="text-white m-0 text-lg"> {title}</p>
        </>
      ) : (
        <>
          <img src={icon} alt="icon" width={30} />
          <p className="text-white m-0 text-lg"> {title}</p>
        </>
      )}
    </Link>
  );
};
