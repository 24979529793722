import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { axiosLogin, axiosRequest } from "../../utils/axiosRequest";

export const superUserCreate = createAsyncThunk(
  "register/superUserCreate",
  async ({ body, navigate }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosLogin.post("users/superuser-create", body);

      navigate("/account/verify");
      localStorage.setItem("email", body.email);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const superUserVerify = createAsyncThunk(
  "register/superUserVerify",
  async ({ body, navigate }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosLogin.post("users/superuser-verify", body);

      navigate("/");
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const superUserInfo = createAsyncThunk(
  "register/superUserInfo",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosRequest.patch("users/superuser-info", body);

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const merchantsFiles = createAsyncThunk(
  "register/merchantsFiles",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post("merchants/files", body, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const merchantsLogo = createAsyncThunk(
  "register/merchantsLogo",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosRequest.post("merchants/logo", body, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      dispatch(getMerchantLogo());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const merchants = createAsyncThunk(
  "register/merchants",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.post("merchants/", body);
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const merchantsUpdate = createAsyncThunk(
  "register/merchantsUpdate",
  async (body, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosRequest.patch("merchants/", body);

      dispatch(getMerchant());
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getMerchant = createAsyncThunk(
  "register/getMerchant",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosRequest.get("merchants/");
      sessionStorage.setItem('merchant', JSON.stringify(data));
      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getMerchantLogo = createAsyncThunk(
  "register/getMerchantLogo",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosRequest.get("merchants/logo");

      return data;
    } catch (error) {
      message.error(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const setError = (state, action) => {
  state.loading = false;
  state.registerStatus = action.payload.error;
};

const setLoading = (state) => {
  state.loading = true;
  state.registerStatus = null;
};

const slice = createSlice({
  name: "register",
  initialState: {
    loading: false,
    registerStatus: null,
    merchant: {},
    logo: null,
  },
  reducers: {},
  extraReducers: {
    [superUserCreate.pending]: setLoading,
    [superUserCreate.fulfilled]: (state, action) => {
      state.loading = false;
      state.registerStatus = "Register success";
    },
    [superUserCreate.rejected]: setError,
    [superUserVerify.pending]: setLoading,
    [superUserVerify.fulfilled]: (state, action) => {
      state.loading = false;
      state.registerStatus = "Register success";
      localStorage.removeItem("email");
    },
    [superUserVerify.rejected]: setError,
    [superUserInfo.pending]: setLoading,
    [superUserInfo.fulfilled]: (state, action) => {
      state.loading = false;
      message.success("Processing complete!");
    },
    [superUserInfo.rejected]: setError,
    [merchantsFiles.pending]: setLoading,
    [merchantsFiles.fulfilled]: (state, action) => {
      state.loading = false;
      message.success("Processing complete!");
    },
    [merchantsFiles.rejected]: setError,
    [merchants.pending]: setLoading,
    [merchants.fulfilled]: (state, action) => {
      state.loading = false;
      message.success("Processing complete!");
      state.registerStatus = "Register success";
    },
    [merchants.rejected]: setError,
    [merchantsUpdate.pending]: setLoading,
    [merchantsUpdate.fulfilled]: (state, action) => {
      state.loading = false;
      message.success("Processing complete!");
      state.registerStatus = "Register success";
    },
    [merchantsUpdate.rejected]: setError,
    [merchantsLogo.pending]: setLoading,
    [merchantsLogo.fulfilled]: (state, action) => {
      state.loading = false;
      message.success("Logo uploaded!");
    },
    [merchantsLogo.rejected]: setError,
    [getMerchant.pending]: setLoading,
    [getMerchant.fulfilled]: (state, action) => {
      state.loading = false;
      state.merchant = action.payload;
    },
    [getMerchant.rejected]: setError,
    [getMerchantLogo.pending]: setLoading,
    [getMerchantLogo.fulfilled]: (state, action) => {
      state.loading = false;
      state.logo = action.payload;
    },
    [getMerchantLogo.rejected]: setError,
  },
});

export default slice.reducer;
