import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function Spinner() {
  return (
    <div className="flex w-full justify-center h-[90vh] items-center">
      <Spin indicator={antIcon} />{" "}
    </div>
  );
}

export default Spinner;
